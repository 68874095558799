const loadFrontEndEnv = (variable, defaultVal) => {
  if (process.env[variable]) {
    return process.env[variable];
  }
  if (window.ENV && window.ENV[variable]) {
    return window.ENV[variable];
  }
  if (defaultVal) {
    console.warn(`Error: was unable to load ${variable} env variable, using default ${defaultVal}.`);
    return defaultVal;
  }
  console.warn(`Error: was unable to load ${variable} env variable.`);
  return null;
};

module.exports = loadFrontEndEnv;