/**
 * The snippet below illustrates how to find and filter (thus causing data loss) 
 * a cyclic reference by using the replacer parameter of JSON.stringify():
 * Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
 *
 * @param message - The message that contains an object with circular reference.
 */
 const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
const removeCircularReference = (message) => JSON.parse(
    JSON.stringify(message, getCircularReplacer())
);

module.exports = { removeCircularReference };
