import {logger} from '../server/bin/logger/log';
import {noop} from './helpers'

function checkMemberState(role, state) {
    return function hasMemberState(members) {
        return members.find((member) => member.role === role && member.state === state)
    }
}

const hasAcdConnected = checkMemberState('ACD', 'CONNECTED');
const hasAgentAlerting = checkMemberState('AGENT', 'ALERTING');
const hasAgentConnected = checkMemberState('AGENT', 'CONNECTED');
const hasAgentDisconnected = checkMemberState('AGENT', 'DISCONNECTED');
const hasCustomerDisconnected = checkMemberState('CUSTOMER', 'DISCONNECTED');
const hasCustomerConnected = checkMemberState('CUSTOMER', 'CONNECTED');

const handleLiveChatEvent = async (event, genesysClient, uiFrame, CHAT_UI_URL, agentConnectivity, resetGenesys) => {
    switch (event?.metadata?.type) {
        case 'member-change': {
            if (event.eventBody.member.state === 'CONNECTED') {
                let members;
                try {
                    members = await genesysClient.current.client.getChatMembers();
                } catch (e) {
                    uiFrame.postMessage({type: 'error'}, CHAT_UI_URL);
                    throw e;
                }

                // All States
                const acdConnected = hasAcdConnected(members)
                const agentAlerting = hasAgentAlerting(members)
                const agentConnected = hasAgentConnected(members)
                const agentDisconnected = hasAgentDisconnected(members)
                const customerConnected = hasCustomerConnected(members)

                // Connecting to Live Agent
                if (acdConnected) {
                    uiFrame.postMessage({type: 'connecting'}, CHAT_UI_URL);
                }

                // Transfer to Another Live Agent
                // Two possible scenarios while transferring to another agent (transfer to Agent or transfer to Queue)
                // 1. Customer Connected & Agent Disconnected & ACD Connected
                // 2. Customer Connected & Agent Disconnected & Agent Alerting
                const agentTransfer = (customerConnected && agentDisconnected && acdConnected) || (customerConnected && agentDisconnected && agentAlerting);

                if (agentTransfer) {
                    logger.info({message: '*** Transferring to an Agent *****'});
                    uiFrame.postMessage({type: 'transferringAgent'}, CHAT_UI_URL);
                }

                // Agent Transferred to Another live Agent.
                // const agentTransferred = agentConnected && agentDisconnected;

                // Agent connected or Transferred successfully
                if ((agentConnected && agentConnectivity.current !== 'CONNECTED') || (agentConnected && customerConnected)) {
                    let agentDataResponse;
                    try {
                        agentDataResponse = await genesysClient.current.client.setAgent({memberId: agentConnected.id});
                    } catch (e) {
                        logger.error({
                            message: '*** Agent not transferred Successfully *****',
                            errMessage: e.message,
                            errStack: e.stack
                        });

                        uiFrame.postMessage({type: 'error', message: 'setAgent'}, CHAT_UI_URL);
                        throw e;
                    }
                    if (agentConnectivity.current !== 'CONNECTED') {
                        uiFrame.postMessage({type: 'connected', agentData: agentDataResponse}, CHAT_UI_URL);
                    } else if (agentConnectivity.current === 'CONNECTED') {
                        // Agents can engage in multiple conversations, therefore, Genesys will fire
                        // a number of connected and disconnected events while switching between
                        // customers. We do not want to handle those events, as concurrent chat
                        // handling should be transparent for end users.
                        noop()
                    } else {
                        logger.info({message: '**** Agent transferred successfully ******'});
                        uiFrame.postMessage({type: 'agentTransferred', agentData: agentDataResponse}, CHAT_UI_URL);
                    }
                    agentConnectivity.current = 'CONNECTED';
                }

            }

            // this condition looks fishy
            if (event.eventBody.member.state === 'DISCONNECTED') {

                let members;
                try {
                    members = await genesysClient.current.client.getChatMembers();
                } catch (e) {
                    uiFrame.postMessage({type: 'error', message: 'DISCONNECTED'}, CHAT_UI_URL);
                    throw e;
                }
                const acdConnected = hasAcdConnected(members)
                const agentAlerting = hasAgentAlerting(members)
                const agentConnected = hasAgentConnected(members)
                const customerDisconnected = hasCustomerDisconnected(members)
                const customerConnected = hasCustomerConnected(members)

                // Agent Disconnected
                if (customerDisconnected && agentConnectivity.current !== 'DISCONNECTED') {
                    agentConnectivity.current = 'DISCONNECTED';
                    uiFrame.postMessage({type: 'disconnected'}, CHAT_UI_URL);
                    resetGenesys(genesysClient);
                }

                // Agent Transferring to Another Live Agent
                // Two possible scenarios while transferring to another agent (transfer to Agent or transfer to Queue)
                // 1. Customer Connected & Agent Disconnected & ACD Connected
                // 2. Customer Connected & Agent Disconnected & Agent Alerting
                const agentTransfering = (customerConnected && customerDisconnected && acdConnected) || (customerConnected && customerDisconnected && agentAlerting);

                if (agentTransfering) {
                    uiFrame.postMessage({type: 'transferringAgent'}, CHAT_UI_URL);
                }

                // Agent Transferred to another Live Agent
                const agentTransferred = agentConnected && customerDisconnected;
                if (customerConnected && agentTransferred && agentConnectivity.current !== 'DISCONNECTED') {
                    let agentDataResponse;

                    try {
                        agentDataResponse = await genesysClient.current.client.setAgent({
                            memberId: agentTransferred.id,
                        });
                    } catch (e) {
                        uiFrame.postMessage({type: 'error', message: 'setAgent'}, CHAT_UI_URL);
                        throw e;
                    }

                    uiFrame.postMessage({type: 'agentTransferred', agentData: agentDataResponse}, CHAT_UI_URL);
                    agentConnectivity.current = 'CONNECTED';
                }
            }

            break;
        }

        case 'message': {
            if (event?.eventBody?.bodyType === 'standard' && event?.eventBody?.sender?.id === genesysClient.current.client.liveAgentId) {
                uiFrame.postMessage({type: 'message', message: event?.eventBody?.body}, CHAT_UI_URL);
            }
            break;
        }

        case 'typing-indicator': {
            if (event?.eventBody?.sender?.id === genesysClient.current.client.liveAgentId) {
                uiFrame.postMessage({type: 'debounceHideTypingIndicator'}, CHAT_UI_URL);
            }
            break;
        }

        default: {
            console.log(event);
        }
    }
};

export default handleLiveChatEvent;