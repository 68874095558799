import {Genesys} from './Genesys';
import handleLiveChatEvent from './handleLiveChatEvent';
import {resetGenesys} from './resetGenesys';
import loadFrontEndEnv from '../env';
import {logger} from '../server/bin/logger/log'

const uiFrame = window.parent;
const CHAT_UI_URL = loadFrontEndEnv('REACT_APP_CHAT_UI_URL');

export const initiateGenesys = (liveChat, genesysClient, agentConnectivity, reInitiate, onConnected) => {
    const newGenesysClient = new Genesys({
        memberId: liveChat.member.id,
        conversationId: liveChat.id,
    });

    genesysClient.current.client = newGenesysClient;

    try {
        newGenesysClient.connectToGenesysFeed({
            socketUri: liveChat.eventStreamUri,
            jwt: liveChat.jwt,
            onConnectionOpen: async () => {
                uiFrame.postMessage({type: 'onConnectionOpen'}, CHAT_UI_URL);
                if (reInitiate) {
                    const members = await newGenesysClient?.client?.getWebchatGuestConversationMembers(liveChat.id);
                    const connectedAgent = members.entities.find(m => m.role === 'AGENT' && m.state === 'CONNECTED');
                    if (connectedAgent) {
                        let agentDataResponse;
                        try {
                            agentDataResponse = await newGenesysClient.setAgent({memberId: connectedAgent.id});
                        } catch (e) {
                            logger.error({message: ' setAgent Error', errMessage: e.message, errStack: e.stack});
                            uiFrame.postMessage({type: 'error', message: 'setAgent'}, CHAT_UI_URL);
                            throw e;
                        }
                        if (onConnected) {
                            onConnected().catch(error => {
                                console.log(`onConnected event error: ${error.message}`)
                            });
                        }
                        return uiFrame.postMessage({type: 'reInitiate', agentData: agentDataResponse}, CHAT_UI_URL);
                    }
                    const isDisconnected = members.entities.find(m => m.role === 'AGENT' && m.state === 'DISCONNECTED');
                    if (isDisconnected) {
                        return uiFrame.postMessage({type: 'terminate'}, CHAT_UI_URL);
                    }
                    logger.info({message: 'connecting'});
                    return uiFrame.postMessage({type: 'connecting'}, CHAT_UI_URL);
                } else {
                    return uiFrame.postMessage({type: 'onInitiateSuccess'}, CHAT_UI_URL);
                }
            },
            onConnectionError: () => {
                logger.error({message: ' Genesys connection Error'});
                uiFrame.postMessage({type: 'error'}, CHAT_UI_URL);
            },
            onEvent: (event) => {
                handleLiveChatEvent(event, genesysClient, uiFrame, CHAT_UI_URL, agentConnectivity, resetGenesys).catch(error => {
                    console.warn(`[MSWCHAT-346] onEvent error: ${error.message}`)
                })
            },
        });
    } catch (e) {
        logger.error({message: 'Genesys Error', errMessage: e.message, errStack: e.stack});
        uiFrame.postMessage({type: 'error'}, CHAT_UI_URL);
        throw e;
    }
}
