import {useRef} from 'react';
import {useGenesys} from '../hooks/useGenesys';

function App() {

// Live agent refs
  const genesysClient = useRef({client: null});

  const agentConnectivity = useRef(null);

  useGenesys(genesysClient, agentConnectivity);

  return (
    <div className="App" data-testid='iframe'>
    </div>
  );
}

export default App;
