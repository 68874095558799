const { createLogger } = require('nrlw-express-scribe');
const { removeCircularReference } = require('../../../utils/removeCircularRefLogs')

const parseBoolean = (val) => (typeof val === 'undefined' ? val : val === true || val === 'true');

const loggerOptions = {
  level: process.env.LOG_LEVEL || 'info',
  programId: process.env.PROGRAM_ID || 'EPMBGXY-CDP-chat-liveagent-ui',
  mode: process.env.PCF_ENV === 'prd' ? 'prod' : 'dev',
  ignoreSiem: parseBoolean(false),
  obfuscate: parseBoolean(false),
  colorize: parseBoolean(false),
  prettyPrint: parseBoolean(false),
  addPrefix: parseBoolean(true),
};

const scribeLogger = createLogger(loggerOptions);

const logger = {
  debug: (message, ...args) => scribeLogger.debug(removeCircularReference(message), ...args),
  info: (message, ...args) => scribeLogger.info(removeCircularReference(message), ...args),
  warn: (message, ...args) => scribeLogger.warn(removeCircularReference(message), ...args),
  error: (message, ...args) => scribeLogger.error(removeCircularReference(message), ...args),
  startTimer: (...args) => scribeLogger.startTimer(...args),
};

module.exports = { logger };