import {useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';
import {initiateGenesys} from '../utils/initiateGenesys';
import {resetGenesys} from '../utils/resetGenesys';
import loadFrontEndEnv from '../env';

const uiFrame = window.parent;
const CHAT_UI_URL = loadFrontEndEnv('REACT_APP_CHAT_UI_URL');


export const useGenesys = (genesysClient, agentConnectivity) => {
    // need to refine(!e.body.includes('virtual-agent:')) once filtering history logic confirmed with Genesys side, we might need to add one more identifider.

    useEffect(() => {
        const buildHistory = (messages) => messages.entities.filter(e => e.bodyType === 'standard' && !e.body.includes('virtual-agent:')).map(e => (
            {
                from: genesysClient.current?.client?.memberId === e.sender.id ? 'customer' : 'live-agent',
                value: e.body,
            }
        ))
        const handleUiFrameEvent = (event) => {
            console.log("Handling event in the iFrame", event.data.type);
            console.log('CHAT_UI_URL: ', CHAT_UI_URL);
            switch (event?.data?.type) {
                case 'initiate': {
                    initiateGenesys(event.data.eventData, genesysClient, agentConnectivity);
                    break;
                }

                case 'reInitiate': {
                    initiateGenesys(event.data.eventData, genesysClient, agentConnectivity, true, async () => {
                        const messages = await genesysClient.current.client?.getMessages();
                        if (isEmpty(messages?.entities)) return

                        const history = buildHistory(messages);
                        uiFrame.postMessage({
                            type: 'live-agent-history',
                            history,
                        }, CHAT_UI_URL);
                    })
                    break;
                }

                case 'userMessage': {
                    try {
                        genesysClient?.current.client?.sendMessage({message: event.data.message});
                        genesysClient.current.client?.sendTypingIndicator();
                    } catch (e) {
                        uiFrame.postMessage({type: 'error'}, CHAT_UI_URL);
                    }
                    break;
                }

                case 'typingIndicator': {
                    genesysClient.current?.client?.sendTypingIndicator();
                    break;
                }

                case 'reset': {
                    resetGenesys(genesysClient);
                    break
                }

                case 'abortConnection': {
                    try {
                        genesysClient.current.client?.disconnectWithAgent();
                    } catch (e) {
                        uiFrame.postMessage({type: 'error'}, CHAT_UI_URL);
                    }
                    break
                }

                default: {
                    console.warn(`[MSWCHAT-346] Unhandled event: ${event.data.type}`, event);
                }
            }
        }

        window.addEventListener('message', handleUiFrameEvent, false);
        uiFrame.postMessage({type: 'ready'}, CHAT_UI_URL);

        return () => {
            window.removeEventListener("message", handleUiFrameEvent, false);
        };
    }, [genesysClient, agentConnectivity]);
}
