import genesysClient from 'purecloud-guest-chat-client/src/purecloud-guest-chat-client/index.js';
import {logger} from '../server/bin/logger/log'

class Genesys {
  constructor({ memberId, conversationId }) {
    this.instance = genesysClient.ApiClient.instance;
    this.memberId = memberId;
    this.conversationId = conversationId;
    this.instance.setEnvironment(genesysClient.PureCloudRegionHosts.ca_central_1);
    this.client = new genesysClient.WebChatApi();
  }

  async connectToGenesysFeed({ socketUri, jwt, onConnectionOpen, onEvent, onConnectionError }) {
    this.socket = new WebSocket(socketUri);
    this.instance.setJwt(jwt);
    this.socket.addEventListener('error', onConnectionError);
    this.socket.addEventListener('open', onConnectionOpen);
    this.socket.addEventListener('message', (e) => {
      try {
        const payload = JSON.parse(e.data);
        onEvent(payload);
      } catch (err) {
        logger.error({message:'Genesys error',errMessage: err.message , errStack: err.stack});
        throw err;
      }
    });
  }

  closeConnection = () => {
    if (this.socket) {
      this.socket.close();
    }
    return true;
  };

  async getChatMembers() {
    const membersData = await this.client.getWebchatGuestConversationMembers(this.conversationId);
    return membersData.entities;
  }

  async setAgent({ memberId }) {
    this.liveAgentId = memberId;
    const agentData = await this.client.getWebchatGuestConversationMember(this.conversationId, memberId);
    this.liveAgentData = agentData;
    return agentData;
  }

  async sendMessage({ message }) {
    await this.client.postWebchatGuestConversationMemberMessages(this.conversationId, this.memberId, {
      body: message,
    });
    return true;
  }

  async sendTypingIndicator() {
    await this.client.postWebchatGuestConversationMemberTyping(this.conversationId, this.memberId);
    return true;
  }

  async disconnectWithAgent() {
    await this.client.deleteWebchatGuestConversationMember(this.conversationId, this.memberId);
    return true;
  }

  async getMessages() {
    return this.client.getWebchatGuestConversationMessages(this.conversationId);
  }
}

export  { Genesys };
